import path from './path';

/*To add a new navigations tab, just add here*/

export const navigations = [
	{
		name : '学生をさがす',
		link : path.landing,
	},
	/*
	{
		name : '検討リスト履歴',
		link : path.orderHistory,
	},
	*/
	{
		name : '面接予定',
		link : path.interview,
	},
	{
		name : '面接履歴',
		link : path.interviewHistory,
	},
	{
		name : "募集要項",
		link : path.jobs,
	},
	{
		name : '内定者（継続学習)',
		link : path.japaneseClass
	},
	{
		name : '入社に向けて',
		link : path.onboarding,
	},
	{
		name : '各種資料',
		link : path.manuals,
	},
];

export const int_result = {
	"〇"               : '合格',
	"△"               : '保留',
	"×"               : '不合格',
	"今日結果は出ない" : "保留",
};

export const vimeo_config = {
	access_token    : '01483854822a573c521bce271cd8c0ca',
	clients_secrets : "i2n19f0Cm3L0HeH0KVU5fHGLENlDo2qmjXApnmds27o4vwT4mbpiPG76XZQK5XHtRew/KQB5CHGpK4a3QQN5CEEKCflMcTcgcAK3bungQPWDde18g9BlrR0aBHuQEhW7",
	client_id       : "110e13dc7d304b6a221ba36ed193f47f0bc4a744",
};

export const hostname = process.env.REACT_APP_BASE_URL;

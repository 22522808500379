import React             from 'react';
import {Route, Switch}   from 'react-router-dom';

import Login             from 'pages/Login';
import Landing           from 'pages/Landing';
import Checkout          from 'pages/Checkout';
import ResetPassword     from 'pages/ResetPassword';
import ForgotPassword    from 'pages/ForgotPassword';
import ApplicantProfile  from 'pages/ApplicantProfile';
import HistoryPage       from 'pages/History';
import Interview         from 'pages/Interview';
import InterviewHistory  from 'pages/InterviewHistory';
import Jobs              from 'pages/Jobs';
import JapaneseClass     from 'pages/JapaneseClass';
import Onboarding        from 'pages/Onboarding';
import path              from 'common/path';
import Error404          from 'components/molecules/404';
import PrivateRoute      from './PrivateRoutes';
import Manuals from 'pages/Manuals';

function Routes () {

	return (
		<Switch>
			<Route        exact = {true} path = {path.login}            component = {Login}/>
			<Route        exact = {true} path = {path.resetPassword}    component = {ResetPassword}/>
			<Route        exact = {true} path = {path.forgotPassword}   component = {ForgotPassword}/>
			<PrivateRoute exact = {true} path = {path.landing}          component = {Landing}/>
			<PrivateRoute exact = {true} path = {path.orderHistory}     component = {HistoryPage}/>
			<PrivateRoute exact = {true} path = {path.jobs}             component = {Jobs}/>
			<PrivateRoute exact = {true} path = {path.checkout}         component = {Checkout}/>
			<PrivateRoute exact = {true} path = {`${path.profile}/:id`} component = {ApplicantProfile}/>
			<PrivateRoute exact = {true} path = {path.interview}        component = {Interview}/>
			<PrivateRoute exact = {true} path = {path.interviewHistory} component = {InterviewHistory}/>
			<PrivateRoute exact = {true} path = {path.japaneseClass}    component = {JapaneseClass}/>
			<PrivateRoute exact = {true} path = {path.manuals}          component = {Manuals}/>
			<PrivateRoute exact = {true} path = {path.onboarding}       component = {Onboarding}/>
			<Route        exact = {true} path = '*'                     component = {Error404}/>
		</Switch>
	);
}

export default Routes;

import React                       from 'react';
import Grid                        from '@material-ui/core/Grid';
import Hidden                      from '@material-ui/core/Hidden';
import Paper                       from '@material-ui/core/Paper';
import Typography                  from '@material-ui/core/Typography';
import Divider                     from '@material-ui/core/Divider';
import {withSnackbar}              from 'notistack';
import Link                        from '@material-ui/core/Link';

import TopNav                      from 'components/molecules/TopNav';
import authStore, {picProfileInfo} from 'redux/authStore';
import MobileNav                   from 'components/molecules/MobileNav';
import apis                        from 'common/apis';
import Log                         from 'common/log';
import utility                     from 'common/utility';
import {errorMsg}                  from 'common/errors';
import Skeleton                    from './Skeleton';

const log = Log ('Manuals', 'info');

class Manuals extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			mobileNav     : false,
			loading       : true,
			picProfile    : null,
			orders        : [],
			cart          : [],
			manuals       : null,
			downloadModal : null,
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {

		this.getProfile ();
		this.getCart ();
		this.getManuals ();
	}

	handleMobileNav = () => {

		this.setState ({
			mobileNav : !this.state.mobileNav,
		});
	}

	getCart = async () => {

		let result;

		try {
			result = await apis.getCart ();
			log.info ({result}, 'cart info get ok');
		}

		catch (err) {
			log.error ({err}, 'error getting cart info');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		this.setState ({
			cart : result.cart
		});
	}

	getProfile = async () => {

		let authInfo   = authStore.getState ();
		let picProfile = authInfo.picProfile;

		if (!picProfile) {
			try {
				picProfile = await apis.getProfile ();
			}
			catch (err) {
				log.error ({err}, 'error getting company pic profile');
				if (err.response && err.response.status === 401) {
					this.notify (errorMsg.logoutMsg, {variant : 'error'});
					utility.redirectLogin ();
					return;
				}
				this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
				return;
			}
			log.info ({pic : picProfile}, 'get pic profile');

			let jobId = picProfile.jobs.length ? picProfile.jobs[0].job_id : '';
			this.setState ({
				picProfile,
				jobId,
			});
			authStore.dispatch (picProfileInfo (picProfile));
		}
		let jobId = picProfile.jobs.length ? picProfile.jobs[0].job_id : '';
		this.setState ({
			picProfile,
			jobId,
		});
	}

	getManuals = async () => {
	
		let manuals;

		try {
			manuals = await apis.getManuals ();
			log.info ({manuals, }, 'manuals list for jclass get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting manuals');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			this.setState ({
				loading : false,
			});
			return;
		}
		this.setState ({
			manuals : manuals.manuals,
			loading : false,
		});
	}

	handleDownload = (fileUrl) => {
		window.open(fileUrl, '_blank');
	}

	render () {

		return (
			<Grid className = 'parent-content'>
				<TopNav mobileNav = {this.handleMobileNav} name = {this.state.picProfile ? this.state.picProfile.name : ''} cartNumber = {this.state.cart.length}/>
				<Hidden only = {['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid container spacing = {3} className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					<Grid item lg = {1} xl = {2}>
					</Grid>
					<Grid item lg = {10} xl = {8} md = {12} sm = {12} xs = {12} className = 'mt-36'>
						{this.state.loading ? <Skeleton /> :
							<Grid>
								<Paper className='p-12' variant='outlined'>
									<Typography variant = 'h5' className='mb-12'> 【各種URL】 </Typography>
									<Divider />
									{this.state.manuals.url?.length ? this.state.manuals.url.map ((item, index) => (
										<Grid key = {index} container spacing = {3} className='mt-8'>
											<Grid item xs={4}>
												<Typography>{item.name} : </Typography>
											</Grid>
											<Grid item xs={4}>
												<Link
													href={item.url_text}
													target="_blank"
													rel="noopener noreferrer"
													className="manual-link"
												>
													こちらをクリック
												</Link>
											</Grid>
											<Grid item xs={4}>
												{item.remarks && <Typography>{item.remarks}</Typography>}
											</Grid>
										</Grid>
									)) : <Typography>URLが存在しません</Typography>}
								</Paper>
								<Paper className='mt-12 p-12' variant='outlined'>
									<Typography variant = 'h5' className='mb-12'> 【各種資料】 </Typography>
									<Divider />
									{this.state.manuals.file?.length ? this.state.manuals.file.map ((item, index) => (
										<Grid container spacing = {3} key = {index} className='mt-8'>
											<Grid item xs={6}>
												<Link
													component="button"
													variant="body2"
													onClick={() => this.handleDownload(item.file_url)}
													className="manual-link"
												>
													{item.file_name}
												</Link>
											</Grid>
											<Grid item xs={6}>
												{item.remarks && <Typography>{item.remarks}</Typography>}
											</Grid>
										</Grid>
									)) : <Typography>資料が存在しません</Typography>}
								</Paper>
							</Grid>
						}
					</Grid>
					<Grid item lg = {1} xl = {2}>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(Manuals);